@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "JustMandrone";
  src: url("./assets/fonts/Just-Mandrawn.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsLight";
  src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

.landing-page {
  background: url("./assets/images/landing-cover.png") no-repeat center center
    fixed;
  background-size: cover;
}

.rezultate-pagina {
  background: url("./assets/images/rezultate-cover.png") no-repeat center center
    fixed;
  background-size: cover;
}

.scrollbar-class::-webkit-scrollbar {
  width: 4px;
}
.scrollbar-class::-webkit-scrollbar-thumb {
  background-color: #048a81;
  border-radius: 26px;
  cursor: pointer;
}

/* Search-bar styles + divider | for work and location */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #33a79f;
  border-radius: 10px;
  border: 3px solid rgb(255, 255, 255); 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  margin-bottom: 13px;
}

.bg-custom-gray {
  background-color: #F0F3F6;
}

.divider::after {
  position: absolute;
  right: 0;
  content: "";
  display: block;
  height: 60%;
  width: 1px;
  background-color: #bdc2c4;
  margin: 1rem 0;
}
@media (max-width: 1024px) {
  .divider::after {
    display: none;
  }

  .fill_arrow {
    fill: #048a81;
  }
}

